#rwc {
  display: none !important;
}

#chat-widget-rating ul {
  justify-content: center !important;
}

.dropbtn {
  background-color: #5f259f;
  margin-left: 5px;
  color: white;
  padding: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  align-self: flex-start;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
}

.dropdown-content a {
  float: left;
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
}

.show {
  display: block;
}

#root {
  height: 100%;
}

.homePageSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgb(245, 245, 245);
}

.contact-form select {
  width: 100%;
  height: 50px;
  border: 1px solid #eeeeee;
  padding: 5px 20px;
}

select:invalid {
  color: rgb(114, 114, 114);
}
option {
  color: black;
}

.custom-html-style table {
  width: 100%;
  font-size: 14px;
  line-height: 1.7;
  max-width: 100%;
  overflow: auto;
  border: 1px solid #f6f6f6;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
}
.custom-html-style thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
.custom-html-style table th {
  text-align: center;
  font-weight: 700;
  border: 1px solid #efefef;
  padding: 10px 6px;
  background-color: #f5f7fa;
  word-break: break-word;
}
.custom-html-style table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-align: center;
}
.custom-html-style ol,
.custom-html-style ul {
  font-size: 14px;
  line-height: 28px;
  padding-left: 36px;
}
.custom-html-style ol,
.custom-html-style ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.custom-html-style li {
  margin-bottom: 8px;
  line-height: 1.7;
}
.custom-html-style ul {
  list-style-type: square;
}

.message-operator.bots-quick-replies {
  margin-top: 0px;
  float: right;
}

.message-operator.bots-quick-replies .button-wrapper {
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  justify-content: flex-end;
  width: 100%;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.message-operator.bots-quick-replies button {
  font-size: 15px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: inherit;
  padding: 8px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  border-radius: 20px;
  margin: 3px;
  overflow: hidden;
}

.bots-quick-replies {
  font-size: 15px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: inherit;
  padding: 8px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  border-radius: 20px;
  margin: 3px;
  overflow: hidden;
}

.msg-send-btn-custom {
  border-radius: 5px;
  align-self: center;
  padding: 9px;
  margin-left: 2px;
}

.rcw-conversation-container.ecw-hidden {
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

#custom-chat-widget-new {
  display: flex;
  position: fixed;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
  display: none;
  bottom: calc(2 * 16px + 65px);
  right: 1px;
  height: auto;
  background-color: #fff;
  border-radius: 16px;
  z-index: 9999999;
  max-width: 370px;
  min-width: 300px;
}

.chat-lang {
  color: lightgrey;
  margin: 8px;
}

.chat-lang:hover {
  color: #fff;
}

.chat-langs {
  align-self: end;
  position: absolute;
  margin-right: 5px;
}
